import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'shortenFileName'
})
export class ShortenFileNamePipe implements PipeTransform {
    transform(value: string): string {
        if (!value) return value;

        const dotIndex = value.lastIndexOf('.');
        const extension = dotIndex !== -1 ? value.substring(dotIndex) : '';
        const baseName = dotIndex !== -1 ? value.substring(0, dotIndex) : value;

        return baseName.slice(0, 25) + extension;
    }
}
