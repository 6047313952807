import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { map } from 'rxjs/operators';
import { User } from '../models/user';
import { environment } from 'environments/environment';
import { getHeaders } from '@core/utils/headers.functions';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  getNotifications(id: any) {
    const headers = getHeaders()
    return this.http.get<any>(`${environment.apiUrl}notifications/${id}`, { headers });
  }
  public handleUnauthorizedError(err: any) {
    if (err.error && err.error.status === 401) {
      this.logout();
      this.router.navigate(['/authentication/signin']);
    }
    return throwError('Session Expired');
  }

  sendEmail(id: any) {
    const headers = getHeaders();
    return this.http.post<any>(`${environment.apiUrl}user-email`, { id }, { headers });
  }

  uploadFile(formdata: FormData) {
    return this.http.put<any>(`${environment.apiUrl}uploadImage`, formdata);
  }

  public imagesCharged: any[] = [];

  setConfig(arg0: string, body: any) {
    const headers = getHeaders();
    return this.http.put<any>(`${environment.apiUrl}settings/${arg0}`, body, { headers });
  }
  getConfig(arg0: string) {
    return this.http.get<any>(`${environment.apiUrl}settings/${arg0}`);
  }

  getUserById(userid: string | null) {
    const headers = getHeaders();
    return this.http.get<any>(`${environment.apiUrl}user/${userid}`, { headers });
  }

  activateUser(token: any, body: any): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}auth/activate/${token}`, body);
  }

  setPassword(body: any) {
    const headers = getHeaders();
    return this.http.post<any>(`${environment.apiUrl}auth/change-password`, body, { headers });
  }

  forgotPassword(body: any) {
    return this.http.post<any>(`${environment.apiUrl}auth/forgot-password`, body);
  }

  signup(body: any) {
    return this.http.post<any>(`${environment.apiUrl}auth/signup`, body);
  }

  private currentUserSubject: BehaviorSubject<User>;
  public currentUser: Observable<User>;

  constructor(private http: HttpClient, private router: Router) {
    this.currentUserSubject = new BehaviorSubject<User>(
      JSON.parse(localStorage.getItem('currentUser') || '{}'),
    );
    this.currentUser = this.currentUserSubject.asObservable();
  }

  public get currentUserValue(): any {
    return this.currentUserSubject.value;
  }

  public setUserValue(body: any) {
    this.currentUserSubject.next(body);
  }

  login(email: string, password: string) {/* 
    const headers: HttpHeaders = getHeaders(); */
    return this.http
      .post<User>(`${environment.apiUrl}auth/admin-login`, {
        email,
        password
      }, /* {headers} */);
  }

  logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('currentUser');
    this.currentUserSubject.next(this.currentUserValue);
    return of({ success: false });
  }
}
