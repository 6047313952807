import { HttpHeaders } from '@angular/common/http';

export function getHeaders(): HttpHeaders {
  const headers = new HttpHeaders().set('Content-Type', 'application/json');
  if (localStorage['currentUser']) {
    const user = JSON.parse(localStorage['currentUser']);
    headers.append('Authorization', user.token)
  }
  return headers;
}
