import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { MaterialModule } from './material.module';
import { FeatherIconsModule } from './components/feather-icons/feather-icons.module';
import { KeysPipe } from './directives/key.pipe';
import { ShortenFileNamePipe } from './directives/shorten-file-name.pipe';
import { FileSizePipe } from './directives/file-size.pipe';
import { SkeletonLoaderComponent } from './components/skeleton-loader/skeleton-loader.component';
import { ClientSupplierFormComponent } from '../advance-table/client-supplier-form/client-supplier-form.component';
import { DndDirective } from './directives/dnd.directive';
import { ProgressComponent } from './components/progress/progress.component';
@NgModule({
  declarations: [
    KeysPipe,
    ShortenFileNamePipe,
    FileSizePipe,
    SkeletonLoaderComponent,
    DndDirective,
    // ProgressComponent
  ],
  imports: [
    CommonModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    MaterialModule,
    FeatherIconsModule,
    KeysPipe,
    ShortenFileNamePipe,
    FileSizePipe,
    SkeletonLoaderComponent,
    DndDirective,
  ],
})
export class SharedModule { }
