// file-size.pipe.ts
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'fileSize'
})
export class FileSizePipe implements PipeTransform {
    transform(sizeInBytes: number): string {
        if (sizeInBytes < 1024) {
            return sizeInBytes + ' Bytes';
        } else if (sizeInBytes < 1048576) {
            return (sizeInBytes / 1024).toFixed(2) + ' KB';
        } else {
            return (sizeInBytes / 1048576).toFixed(2) + ' MB';
        }
    }
}