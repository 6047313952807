import { RouteInfo } from './sidebar.metadata';
export const ROUTES: RouteInfo[] = [
  {
    access: 'all',
    path: '',
    title: 'MENUITEMS.MAIN.TEXT',
    iconType: '',
    icon: '',
    class: '',
    groupTitle: true,
    badge: '',
    badgeClass: '',
    submenu: [],
  },
  {
    path: 'dashboard',
    access: 'all',
    title: 'Indicadores',
    iconType: 'feather',
    icon: 'home',
    class: '',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    submenu: []
  },
  {
    access: 'admin, almacen',
    path: '',
    title: 'Administración',
    iconType: 'feather',
    icon: 'archive',
    class: 'menu-toggle',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    submenu: [
      {
        access: 'admin',
        path: 'accounts-payable',
        title: 'Cuentas por pagar',
        iconType: '',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        submenu: [],
      },
      {
        access: 'admin',
        path: 'accounts-receivable',
        title: 'Cuentas por cobrar',
        iconType: '',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        submenu: [],
      }
    ],
  },
  // {
  //   access: 'admin',
  //   path: 'accounts-payable',
  //   title: 'Cuentas por pagar',
  //   iconType: 'feather',
  //   icon: 'archive',
  //   class: '',
  //   groupTitle: false,
  //   badge: '',
  //   badgeClass: '',
  //   submenu: [],
  // },
  // {
  //   access: 'admin',
  //   path: 'accounts-receivable',
  //   title: 'Cuentas por cobrar',
  //   iconType: 'feather',
  //   icon: 'archive',
  //   class: '',
  //   groupTitle: false,
  //   badge: '',
  //   badgeClass: '',
  //   submenu: [],
  // },
  {
    access: 'admin',
    path: 'reports',
    title: 'Reportes',
    iconType: 'feather',
    icon: 'archive',
    class: '',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    submenu: [],
  },
  {
    access: 'admin, torre',
    path: 'clients',
    title: 'MENUITEMS.ADVANCE-TABLE.TEXT',
    iconType: 'feather',
    icon: 'users',
    class: '',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    submenu: [],
  },
  {
    access: 'admin, compras',
    path: 'suppliers',
    title: 'Proveedores',
    iconType: 'feather',
    icon: 'users',
    class: '',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    submenu: [],
  },
  {
    access: 'admin, compras, almacen',
    path: 'products',
    title: 'Refacciones',
    iconType: 'feather',
    icon: 'users',
    class: '',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    submenu: [],
  },
  {
    access: 'admin, torre',
    path: 'torre-control',
    title: 'MENUITEMS.TORRE-CONTROL.TEXT',
    iconType: 'feather',
    icon: 'archive',
    class: '',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    submenu: [],
  },
  {
    access: 'admin, almacen',
    path: '',
    title: 'Compras',
    iconType: 'feather',
    icon: 'archive',
    class: 'menu-toggle',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    submenu: [
      {
        access: 'admin',
        path: 'requisitions',
        title: 'Requisiciones',
        iconType: '',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        submenu: [],
      },
      {
        access: 'admin',
        path: 'purchases',
        title: 'Compras',
        iconType: '',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        submenu: [],
      }
    ],
  },
  // {
  //   path: 'requisitions',
  //   access: 'admin, compras',
  //   title: 'Requisiciones',
  //   iconType: 'feather',
  //   icon: 'file-text',
  //   class: '',
  //   groupTitle: false,
  //   badge: '',
  //   badgeClass: '',
  //   submenu: []
  // },
  // {
  //   path: 'purchases',
  //   access: 'admin, compras',
  //   title: 'Compras',
  //   iconType: 'feather',
  //   icon: 'file-text',
  //   class: '',
  //   groupTitle: false,
  //   badge: '',
  //   badgeClass: '',
  //   submenu: []
  // },
  {
    access: 'admin, operations',
    path: 'operations',
    title: 'Operaciones',
    iconType: 'feather',
    icon: 'info',
    class: '',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    submenu: [],
  },
  {
    access: 'admin, taller',
    path: 'taller',
    title: 'Jefe de taller',
    iconType: 'feather',
    icon: 'archive',
    class: '',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    submenu: [],
  },
  {
    access: 'admin, almacen',
    path: '',
    title: 'Almacén',
    iconType: 'feather',
    icon: 'archive',
    class: 'menu-toggle',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    submenu: [
      // {
      //   access: 'admin, almacen',
      //   path: '/almacen/compras',
      //   title: 'Ordenes de compra',
      //   iconType: '',
      //   icon: '',
      //   class: 'ml-menu',
      //   groupTitle: false,
      //   badge: '',
      //   badgeClass: '',
      //   submenu: [],
      // },
      {
        access: 'admin, almacen',
        path: '/almacen/entry',
        title: 'Entradas',
        iconType: '',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        submenu: [],
      },
      {
        access: 'admin, almacen',
        path: '/almacen/exit',
        title: 'Salidas',
        iconType: '',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        submenu: [],
      },
      {
        access: 'admin, almacen',
        path: '/almacen/inventario',
        title: 'Inventario',
        iconType: '',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        submenu: [],
      },
      // {
      //   access: 'admin, almacen',
      //   path: '/almacen/transferences',
      //   title: 'Transferencias',
      //   iconType: '',
      //   icon: '',
      //   class: 'ml-menu',
      //   groupTitle: false,
      //   badge: '',
      //   badgeClass: '',
      //   submenu: [],
      // },
      {
        access: 'admin, almacen',
        path: '/almacen/tools',
        title: 'Maquinaria y herramientas',
        iconType: '',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        submenu: [],
      },
      // {
      //   access: 'admin',
      //   path: '/almacen/assignments',
      //   title: 'Asignación de herramienta',
      //   iconType: '',
      //   icon: '',
      //   class: 'ml-menu',
      //   groupTitle: false,
      //   badge: '',
      //   badgeClass: '',
      //   submenu: [],
      // },
    ],
  },
  /* {
    access: 'all',
    path: 'projects',
    title: 'MENUITEMS.PROJECTS.TEXT',
    iconType: 'feather',
    icon: 'briefcase',
    class: '',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    submenu: [],
  },
  {
    access: 'admin',
    path: 'payments',
    title: 'MENUITEMS.PAYMENTS.TEXT',
    iconType: 'feather',
    icon: 'dollar-sign',
    class: '',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    submenu: [],
  }, */
  {
    path: '',
    access: 'admin',
    title: 'Catálogos',
    iconType: 'feather',
    icon: 'settings',
    class: 'menu-toggle',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    submenu: [
      {
        access: 'admin',
        path: '/catalogs/sucursales',
        title: 'Sucursales',
        iconType: '',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        submenu: [],
      },
      {
        access: 'admin',
        path: '/catalogs/documentos',
        title: 'Documentos',
        iconType: '',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        submenu: [],
      },
      {
        access: 'admin',
        path: '/catalogs/activities',
        title: 'Actividades',
        iconType: '',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        submenu: [],
      },
      {
        access: 'admin',
        path: '/catalogs/almacen',
        title: 'Almacenes',
        iconType: '',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        submenu: [],
      },
      {
        access: 'admin',
        path: '/catalogs/marcas',
        title: 'Marcas (Unidades)',
        iconType: '',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        submenu: [],
      },
      {
        access: 'admin',
        path: '/catalogs/marca-refacciones',
        title: 'Marcas (Refacciones)',
        iconType: '',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        submenu: [],
      },
      {
        access: 'admin',
        path: '/catalogs/aseguradoras',
        title: 'Aseguradoras',
        iconType: '',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        submenu: [],
      },
      {
        access: 'admin',
        path: '/catalogs/unidades',
        title: 'Unidades',
        iconType: '',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        submenu: [],
      },
      {
        access: 'admin',
        path: '/catalogs/colores',
        title: 'Colores',
        iconType: '',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        submenu: [],
      }
    ]
  },
  {
    access: 'admin',
    path: '',
    title: 'Usuarios',
    iconType: 'feather',
    icon: 'user-check',
    class: 'menu-toggle',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    submenu: [
      {
        access: 'admin',
        path: '/users/admin',
        title: 'Administradores',
        iconType: '',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        submenu: [],
      },
      // {
      //   access: 'admin',
      //   path: '/users/administrativo',
      //   title: 'Administrativos',
      //   iconType: '',
      //   icon: '',
      //   class: 'ml-menu',
      //   groupTitle: false,
      //   badge: '',
      //   badgeClass: '',
      //   submenu: [],
      // },
      {
        access: 'all',
        path: '/users/operador',
        title: 'Operativos',
        iconType: '',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        submenu: [],
      },
      /* {
        access: 'admin',
        path: '/users/coordinador',
        title: 'Coordinadores',
        iconType: 'feather',
        icon: 'trello',
        class: '',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        submenu: [],
      }, */
      /* {
        access: 'admin',
        path: '/users/residente',
        title: 'Residentes',
        iconType: '',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        submenu: [],
      }, */
    ],
  },
  // Common Modules
  /* {
    path: '',
    title: 'Authentication',
    iconType: 'feather',
    icon: 'user-check',
    class: 'menu-toggle',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    submenu: [
      {
        path: '/authentication/signin',
        title: 'Sign In',
        iconType: '',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        submenu: [],
      },
      {
        path: '/authentication/signup',
        title: 'Sign Up',
        iconType: '',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        submenu: [],
      },
      {
        path: '/authentication/forgot-password',
        title: 'Forgot Password',
        iconType: '',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        submenu: [],
      },
      {
        path: '/authentication/locked',
        title: 'Locked',
        iconType: '',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        submenu: [],
      },
      {
        path: '/authentication/page404',
        title: '404 - Not Found',
        iconType: '',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        submenu: [],
      },
      {
        path: '/authentication/page500',
        title: '500 - Server Error',
        iconType: '',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        submenu: [],
      },
    ],
  },
  {
    path: '',
    title: 'Extra Pages',
    iconType: 'feather',
    icon: 'anchor',
    class: 'menu-toggle',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    submenu: [
      {
        path: '/extra-pages/blank',
        title: 'Blank Page',
        iconType: '',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        submenu: [],
      },
    ],
  },
  {
    path: '',
    title: 'Multi level Menu',
    iconType: 'feather',
    icon: 'chevrons-down',
    class: 'menu-toggle',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    submenu: [
      {
        path: '/multilevel/first1',
        title: 'First',
        iconType: '',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        submenu: [],
      },
      {
        path: '/',
        title: 'Second',
        iconType: '',
        icon: '',
        class: 'ml-sub-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        submenu: [
          {
            path: '/multilevel/secondlevel/second1',
            title: 'Second 1',
            iconType: '',
            icon: '',
            class: 'ml-menu2',
            groupTitle: false,
            badge: '',
            badgeClass: '',
            submenu: [],
          },
          {
            path: '/',
            title: 'Second 2',
            iconType: '',
            icon: '',
            class: 'ml-sub-menu2',
            groupTitle: false,
            badge: '',
            badgeClass: '',
            submenu: [
              {
                path: '/multilevel/thirdlevel/third1',
                title: 'third 1',
                iconType: '',
                icon: '',
                class: 'ml-menu3',
                groupTitle: false,
                badge: '',
                badgeClass: '',
                submenu: [],
              },
            ],
          },
        ],
      },
      {
        path: '/multilevel/first3',
        title: 'Third',
        iconType: '',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        submenu: [],
      },
    ],
  }, */
];
