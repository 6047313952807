import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'environments/environment.development';
import { getHeaders } from '@core/utils/headers.functions';
const apiURL = environment.apiUrl;

@Injectable({
    providedIn: 'root'
})
export class APIService {
    updateNotification(id: any) {
        const headers = getHeaders()
        return this.http.put<any>(`${apiURL}notification/read/${id}`, { headers });
    }
    updateOrderItem(id: any, repair: any) {
        const headers = getHeaders()
        return this.http.put<any>(`${apiURL}service-order-item/${id}`, repair, { headers });
    }

    downloadPurchaseOrder(body: any): Observable<Blob> {
        return this.http.post(`${apiURL}purchases-by-supplier`,
            body,
            {
                responseType: 'blob',
                headers: {
                    'Accept': 'application/pdf'
                }
            }
        );
    }

    addDamageItems(newDamages: any) {
        const headers = getHeaders()
        return this.http.post<any>(`${apiURL}service-order-items`, newDamages, { headers });
    }
    createFileRecord(body: { FileType: string; FileName: any; FileFormat: any; FileUrl: any; }): any {
        const headers = getHeaders()
        return this.http.post<any>(`${apiURL}files`, body, { headers });
    }

    updateFileRecord(id: any, body: { FileType: string; FileName: any; FileFormat: any; FileUrl: any; }): any {
        const headers = getHeaders()
        return this.http.put<any>(`${apiURL}files/${id}`, body, { headers });
    }

    downloadQuote(id: any): Observable<Blob> {
        const headers = getHeaders()
        return this.http.get(`${apiURL}quotes/${id}/pdf`, {
            headers,
            responseType: 'blob', // Indicamos que esperamos un blob como respuesta
        });
    }

    downloadPlan(id: any): Observable<Blob> {
        const headers = getHeaders()
        return this.http.get(`${apiURL}service-order/work-plan/${id}`, {
            headers,
            responseType: 'blob', // Indicamos que esperamos un blob como respuesta
        });
    }
    generateQuote2(quote: { Status: string; CurrencyCode: string; Subtotal: any; Taxes: any; Total: any; Active: boolean; validityDays: any; ServiceOrderId: string | null; QuoteItem: any; }) {
        const headers = getHeaders()
        return this.http.post<any>(`${apiURL}qoutes`, quote, { headers });
    }
    validateSKU(SKU: string) {
        const headers = getHeaders()
        return this.http.post<any>(`${apiURL}validate-sku`, { SKU }, { headers });
    }
    searchAccidentNumber(body: any) {
        const headers = getHeaders()
        return this.http.post<any>(`${apiURL}accident-data`, body, { headers });
    }
    searchCustomerByEmail(email: any) {
        const headers = getHeaders()
        return this.http.post<any>(`${apiURL}customer-by-email`, { search: email }, { headers });
    }
    updatePurchaseItem(id: any, element: any) {
        const headers = getHeaders()
        return this.http.put<any>(`${apiURL}purchase-order-item/${id}`, element, { headers });
    }

    getInventory(offset: any, limit: any, branch: any, search: string) {
        const headers = getHeaders();
        return this.http.get<any>(`${apiURL}product-inventory?limit=${limit}&offset=${offset}&branch=${branch}&search=${search}`, { headers });
    }

    generateQuote(arg0: { html: string; }) {
        return this.http.post(`${apiURL}service-order/generateQuote`, arg0, {
            responseType: 'blob', // Indicamos que esperamos un blob como respuesta
        });
    }
    downloadFile(id: string) {
        return this.http.get(`${apiURL}service-order/files/${id}`, {
            responseType: 'blob', // Indicamos que esperamos un blob como respuesta
        });
    }
    getProductsList() {
        const headers = getHeaders();
        return this.http.get<any>(`${apiURL}products`, { headers });
    }
    getProductsList3(type: string) {
        const headers = getHeaders();
        return this.http.get<any>(`${apiURL}products?type=${type}`, { headers });
    }
    getProductsList2(limit: number, offset: number, text: string, type: string) {
        const headers = getHeaders();
        return this.http.get<any>(`${apiURL}products?limit=${limit}&offset=${offset}&text=${text}&type=${type}`, { headers });
    }
    uploadFile(formData: FormData) {
        const httpOptions = {
            reportProgress: true,  // Para obtener el progreso de la subida
            observe: 'events' as const, // Aseguramos que observe es de tipo 'events'
            responseType: 'json' as const  // Para asegurarnos de que la respuesta sea JSON
        };

        return this.http.put<any>(`${apiURL}/uploadFile`, formData, {
            reportProgress: httpOptions.reportProgress,
            observe: httpOptions.observe,
            responseType: httpOptions.responseType
        });
    }
    createProduct(formValue: any) {
        const headers = getHeaders();
        return this.http.post<any>(`${apiURL}products`, formValue, { headers });
    }
    updateService(id: any, toUpdate: any) {
        const headers = getHeaders()
        return this.http.put<any>(`${apiURL}service-order/${id}`, toUpdate, { headers });
    }
    updateServiceFile(id: any, toUpdate: any) {
        const headers = getHeaders()
        return this.http.put<any>(`${apiURL}service-order-file/${id}`, toUpdate, { headers });
    }
    getUsersByRole(
        limit: number,
        offset: number,
        search: string,
        rol: any,
        column: string,
        order: string
    ): Observable<any> {
        const headers = getHeaders();
        return this.http.get<any>(
            `${apiURL}users/${rol}?limit=${limit}&offset=${offset}&search=${search}&column=displayName&order=ASC`,
            { headers }
        );
    }
    generateWorkPlan(body: { ServiceOrderItems: any; }, ServiceOrderId: any) {
        const headers = getHeaders()
        return this.http.post<any>(`${apiURL}service-order/work-plan/${ServiceOrderId}`, body, { headers });
    }
    generatePlan(body: { ServiceOrderItems: any; }, ServiceOrderId: any) {
        const headers = getHeaders()
        return this.http.post<any>(`${apiURL}service-order/repair-plan/${ServiceOrderId}`, body, { headers });
    }
    authService(body: { ServiceOrderItems: any; }, ServiceOrderId: any) {
        const headers = getHeaders()
        return this.http.post<any>(`${apiURL}service-order/auth/${ServiceOrderId}`, body, { headers });
    }
    updatePurchaseOrder(id: string | null, data: any) {
        const headers = getHeaders()
        return this.http.put<any>(`${apiURL}purchase-order/${id}`, data, { headers });
    }
    rejectPurchaseOrder(id: any) {
        const headers = getHeaders()
        return this.http.delete<any>(`${apiURL}purchase-order/${id}`, { headers });
    }
    getPurchaseOrder(id: any) {
        const headers = getHeaders()
        return this.http.get<any>(`${apiURL}purchase-order/${id}`, { headers });
    }
    getAllPurchaseOrders(offset: any, limit: any) {
        const headers = getHeaders()
        return this.http.get<any>(`${apiURL}purchase-orders?limit=${limit}&offset=${offset}&status=Autorizada`, { headers });
    }
    getPurchasesByOrder(id: string | null): Observable<any> {
        const headers = getHeaders()
        return this.http.get<any>(`${apiURL}service-order/${id}/purchase-order`, { headers });
    }
    generatePurchaseOrder(id: any) {
        const headers = getHeaders()
        return this.http.post<any>(`${apiURL}service-order/${id}/purchase-order`, null, { headers });
    }
    getOrderById(id: string | null) {
        const headers = getHeaders()
        return this.http.get<any>(`${apiURL}service-order/${id}`, { headers });
    }
    saveDamageSurvey(body: { ServiceOrderItems: any[]; }, id: string | null) {
        const headers = getHeaders()
        return this.http.post<any>(`${apiURL}service-order/${id}/levantamiento`, body, { headers });
    }
    getAllOrders(limit: any, offset: any) {
        return this.http.get<any[]>(`${apiURL}service-order?limit=${limit}&offset=${offset}`);
    }

    getAllOrders2(limit: any, offset: any, search: string, status: string, sDate: string, eDate: string) {
        return this.http.get<any[]>(`${apiURL}service-order?limit=${limit}&offset=${offset}&search=${search}&status=${status}&sDate=${sDate}&eDate=${eDate}`);
    }

    constructor(private http: HttpClient) { }

    obtenerFormWithAnswers(idForm: any): Observable<any[]> {
        return this.http.get<any[]>(`${apiURL}form/${idForm}`);
    }

    saveServiceOrder(body: any) {
        return this.http.post<any[]>(`${apiURL}service-order`, body);
    }
    saverOrderAnswers(body: { FormId: number; UserId: any; Answers: any; ServiceOrderId: any; }) {
        return this.http.post<any[]>(`${apiURL}forms/answers`, body);
    }
    obtenerFormByOrder(id: any) {
        const headers = getHeaders()
        return this.http.get<any>(`${apiURL}service-order/${id}/inventario`, { headers });
    }

    getAllInventoryMovements(): Observable<any[]> {
        const headers = getHeaders()
        return this.http.get<any[]>(`${apiURL}inventory-movements`, { headers });
    }
}
